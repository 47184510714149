export const URL_RRSS = process.env.URL_RRSS;
export const BASE_URL_COMPUTED = window.location.origin;

export const DRAWER_WIDTH = 300;

export const HEADER_HEIGHT = 48;
export const HEADER_HEIGHT_WITH_SUBMENU = 84;
export const RIGHT_DEFAULT_DRAWER_WIDTH = 600;
export const MAX_DEFAULT_DRAWER_WIDTH = 1200;
export const SM_BREAKPOINT = 600;
export const MINI_SIDE_PANEL_WIDTH = 56;
export const MINI_SIDE_PANEL_DENSE_WIDTH = 45;
export const POP_UP_WIDTH_WIDESCREEN = 340;
export const POP_UP_WIDTH_MOBILE = 240;
export const MODES = {PRINT: 'print', INFO: 'info'};

export const ROLES = {ADMINISTRATOR: 'ADMINISTRATOR', MODERATOR: 'MODERATOR', COLLABORATOR: 'COLLABORATOR', REGISTERED: 'REGISTERED'};

export const operators = [{value: 'and', label: 'and'}, {value: 'or', label: 'or'}];

export const INITIAL_VIEWPORT = {
  latitude: 0,
  longitude: 0,
  zoom: 2,
  bearing: 0,
  pitch: 0
};

export const LANGUAGES = [
  {name: 'spanish', id: 'es'},
  {name: 'english', id: 'en'},
  {name: 'french', id: 'fr'},
];

export const ENTITIES = {FEATURED: 'featured', CONFLICT: 'conflict'};

export const MAPSTYLES = [
  {
    'label': 'MapTiler Basic',
    'thumbnail': '/images/basemaps/basic.png',
    'id': 'https://api.maptiler.com/maps/basic-v2/style.json?key=REPToVqqY31DxAYPLPiA',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'MapTiler Topo',
    'thumbnail': '/images/basemaps/topo.png',
    'id': 'https://api.maptiler.com/maps/topo-v2/style.json?key=REPToVqqY31DxAYPLPiA',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'MapTiler Satellite',
    'thumbnail': '/images/basemaps/sat.png',
    'id': 'https://api.maptiler.com/maps/satellite/style.json?key=REPToVqqY31DxAYPLPiA',
    'firstTopLayer': 'place-other'
  }
];

export const INITIAL_MAPSTYLE_URL = MAPSTYLES[0].id;

export const BASE_URL_API = process.env.API_BASE_URL || 'http://localhost/api/v1';

export const LIMIT_CONFLICT_RESULTS = 10;

export const legendIconsMap: Record<string, string> = {
  'nuclear': '-210',
  'mineral-ores-and-building-materials-extraction': '-180',
  'waste-management': '-30',
  'fossil-fuels-and-climate-justice-energy': '-119',
  'water-management': '-148',
  'infrastructure-and-built-environment': '0',
  'tourism-recreation': '-269',
  'biodiversity-conservation-conflicts': '-59',
  'industrial-and-utilities-conflicts': '-90',
  'other': '-178',
  'biomass-and-land-conflicts-forests-agriculture-and-livestock-management': '-238',
};

export const NavBarConfig = [
  {name: 'home', url: '/', submenu: []},
  {
    name: 'about',
    submenu: [
      {key: 'story', url: 'story'},
      {key: 'team', url: 'team'},
      {key: 'network', url: 'network'},
      {key: 'funding', url: 'funding'},
      {key: 'FAQ', url: 'FAQ'},
      {key: 'dataPolicy', url: 'dataPolicy'},
      {key: 'contact', url: 'contact'},
    ]
  },
  {
    name: 'resources',
    submenu: [
      {key: 'publications', url: 'publications'},
      {key: 'tools', url: 'tools'},
      {key: 'tutorials', url: 'tutorials'},
      {key: 'glossary', url: 'glossary'},
      {key: 'news', url: 'news'},
    ]
  },
  {
    name: 'collaborate',
    submenu: [
      {key: 'enterCase', url: 'enterCase'},
      {key: 'dataPolicy', url: 'dataPolicy'},
      {key: 'volunteer', url: 'volunteer'},
      {key: 'useData', url: 'useData'},
      {key: 'feedback', url: 'feedback'},
    ]
  },
  {name: 'myCases', url: 'myCases', submenu: [], private: true},
  {name: 'newCase', url: 'newCase', submenu: [], private: true},
  /*{name: 'moderators', url: 'moderators', submenu: [], private: true},*/
  {name: 'donate', url: 'donate', submenu: []},
];

export const filters = [
  'country', 
  'company', 
  'commodity', 
  'type', 
  'featured', 
  'conflict', 
  'category', 
  'reaction', 
  'status', 
  'project_status', 
  'supporter'
];

// API URLS
export const API_MANIFEST = `${BASE_URL_API}/manifest/`;
export const API_MAP_DATA = `${BASE_URL_API}/conflicts/?format=geojson`;
export const API_BROWSE_MAPS = `${BASE_URL_API}/browse_maps/`;
export const API_GET_FILTER_BY_ID = (filter_id: string) => `${BASE_URL_API}/filters/${filter_id}/`;


export const API_USER_SESSION = `${BASE_URL_API}/profile/`;

export const API_CONFLICT_DETAIL = (slug: string, conflictLang: string,) => `${BASE_URL_API}/conflicts/${slug}/${conflictLang}`;
export const API_CONFLICT_DETAIL_WITH_FILTER = (filter: string, searchText: string, limit: number, offset: number) => `${BASE_URL_API}/${filter}/?search=${searchText}&limit=${limit}&offset=${offset}`;
//export const API_NAVBAR = `${BASE_URL_API}/navbar/`;
export const API_GET_FEATURED_MAP_LIST = (limit: number, offset: number) => `${BASE_URL_API}/featured/?limit=${limit}&offset=${offset}`;
export const API_GET_FEATURED_MAP_DETAIL = (slug: string) => `${BASE_URL_API}/featured/${slug}`;
